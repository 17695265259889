import { updatePassword } from "../../api/application/index";
export default {
  data() {
    return {
      newpassword: "",
      password: ""
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    out() {
      if (this.password == '' || this.newpassword == '') {
        $Toast('密码不能为空');
        return;
      }
      updatePassword({
        newPassword: this.password,
        rePassword: this.newpassword
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          $Toast('修改成功');
          setTimeout(() => {
            this.$router.back(-1);
          }, 1000);
        }
      });
    }
  }
};