import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_field = _resolveComponent("van-field");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "修改密码",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_field, {
    modelValue: $data.password,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.password = $event),
    type: "password",
    label: "新密码"
  }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
    modelValue: $data.newpassword,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.newpassword = $event),
    type: "password",
    label: "确认新密码"
  }, null, 8, ["modelValue"]), _createElementVNode("div", {
    class: "out",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.out && $options.out(...args))
  }, " 确认 ")], 64);
}